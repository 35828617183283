import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CTLDetails } from 'types/CTL'

type Props = {
  programDetails: CTLDetails | undefined
  clientCode: string
  applyButtonDisabled: boolean
  onSetApplyButtonDisabled: (value: boolean) => void
  isMobile
}
export default function CTLProgramInfo({ programDetails, clientCode, applyButtonDisabled, onSetApplyButtonDisabled, isMobile }: Props) {
  const navigate = useNavigate()

  const navigateToEnrollment = () => {
    navigate(`/appointment?ctl=true&cid=${programDetails?.carrierPId}`)
  }

  return (
    <>
      <div className="ctl-info-container">
        <Column className="ctl-left-column">
          <Typography size={25} font="bold" styles={{ marginBottom: 20 }}>
            Weight loss is a journey. Get started with Weight Management Care Path.
          </Typography>
          <Typography size={18} styles={{ marginBottom: 30 }}>
            Making the commitment to lose weight can be a critical step in improving your overall health. For successful, long-term weight loss,
            you'll need to make permanent changes in your habits and lifestyle. We know this isn't an easy process and can be extremely difficult
            alone, that's why Weight Management Care Path is here for you! By enrolling in Weight Management Care Path program, you'll have immediate
            access to some of the best resources that will help aid in your weight loss.
          </Typography>

          <Typography size={18} styles={{ marginBottom: 10 }} font="semi-bold">
            Your weight loss toolkit will include:
          </Typography>
          <div style={{ fontFamily: 'Raleway' }}>
            <Row>
              <Icon name="salad" styles={{ marginRight: 10, width: 25 }} />A designated health coach
            </Row>
            <Row>
              <Icon name="doctor" styles={{ marginRight: 10, width: 25 }} />
              Access to a clinical pharmacist
            </Row>
          </div>
          {programDetails && programDetails.customProgramOptIn !== '' && (
            <Row styles={{ marginTop: 20 }}>
              <Checkbox checked={!applyButtonDisabled} styles={{ marginRight: 5 }} onClick={() => onSetApplyButtonDisabled(!applyButtonDisabled)} />
              <Typography size={18} styles={{ marginBottom: 10 }}>
                {programDetails.customProgramOptIn}
              </Typography>
            </Row>
          )}
          {programDetails && !programDetails.programClosed ? (
            applyButtonDisabled ? (
              <></>
            ) : (
              <Button pageName="CTLInfo" text="Apply" onClick={navigateToEnrollment} className="ctl-apply-button" />
            )
          ) : (
            <div style={{ height: 25 }} />
          )}
          {programDetails && programDetails.message && (
            <Row styles={{ marginBottom: 10 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: programDetails.message,
                }}
              />
            </Row>
          )}
          {programDetails && programDetails.customProgramInfo && (
            <Row styles={{ marginBottom: 10 }}>
              <div
                style={{ fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{
                  __html: programDetails.customProgramInfo,
                }}
              />
            </Row>
          )}
        </Column>
        <Column className="ctl-right-column">
          <>
            <img
              style={{ maxWidth: 300, marginBottom: 15, width: '100%' }}
              src={'https://triacentral.blob.core.windows.net/dev-storage/portal/images/WMCP-TriaLogo-Final.jpg'}
              alt={`${clientCode}-logo`}
            />
          </>
        </Column>
      </div>

      <hr style={{ marginBottom: 25 }} />
      <div style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)', padding: 15, borderRadius: 5, margin: '5px 20px' }}>
        <Row>
          <Typography size={25} styles={{ marginBottom: 10 }} font="semi-bold">
            Is Weight Management Care Path right for you?
          </Typography>
        </Row>
        <Typography size={18}>
          {!programDetails?.participantType
            ? `Weight Management Care Path is recommended for individuals who:`
            : `Weight Management Care Path is recommended for ${programDetails.participantType} who:`}
        </Typography>
        {programDetails && programDetails.customCriteria.length > 0 ? (
          programDetails.customCriteria.map((criteria) => (
            <Row styles={{ marginLeft: 30 }}>
              <Typography>-</Typography>
              <Typography>{criteria}</Typography>
            </Row>
          ))
        ) : (
          <>
            <Row>
              <Typography>- Have a smartphone</Typography>
            </Row>
            <Row>
              <Typography>- BMI of 30+ </Typography>
              <span style={{ fontWeight: 'bold' }}>OR</span>
            </Row>
            <Row>
              <Typography>- BMI between 27-29.9 with a chronic condition</Typography>
            </Row>
          </>
        )}
      </div>
      {isMobile ? (
        <>
          {programDetails && programDetails.story.length > 0 && (
            <div style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)', borderRadius: 5, padding: 35, margin: '65px 20px', textAlign: 'center' }}>
              <Column styles={{ width: '100%' }}>
                {programDetails && programDetails.title && <Typography variant="h1">{programDetails.title}</Typography>}
                <br></br>
                {programDetails &&
                  programDetails.story &&
                  programDetails.story.map((paragraph) => {
                    return (
                      <>
                        <Typography>{paragraph.paragraph}</Typography>
                        <br></br>
                      </>
                    )
                  })}
              </Column>
              <Column styles={{ width: '100%', marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
                <img src={programDetails?.imageUrl} alt={programDetails?.caption} />
                {programDetails && <Typography>{programDetails.caption}</Typography>}
              </Column>
            </div>
          )}
        </>
      ) : (
        <>
          {programDetails && programDetails.story.length > 0 && (
            <div style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)', borderRadius: 5, padding: 35, margin: '65px 20px' }}>
              <Row>
                <Column styles={{ width: '55%' }}>
                  {programDetails && programDetails.title && (
                    <Typography variant="h1" styles={{ width: '85%' }}>
                      {programDetails.title}
                    </Typography>
                  )}
                  <br></br>
                  {programDetails &&
                    programDetails.story &&
                    programDetails.story.map((paragraph) => {
                      return (
                        <>
                          <Typography>{paragraph.paragraph}</Typography>
                          <br></br>
                        </>
                      )
                    })}
                </Column>
                <Column styles={{ width: '30%', marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
                  <img src={programDetails?.imageUrl} alt={programDetails?.caption} />
                  {programDetails && <Typography>{programDetails.caption}</Typography>}
                </Column>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  )
}

import logo from 'Assets/Images/TriaLogo_White.svg'
import Typography from 'Components/Base/Typography'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React, { useEffect, useState } from 'react'
import { Navbar as BootstrapNav, Image, Nav, NavDropdown } from 'react-bootstrap'
import { useAuth } from 'react-oauth2-pkce'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { authLogout, getAccessToken } from 'reducers/authSlice'
import { getErrors, setLoginError } from 'reducers/errorSlice'
import { getMultifactor } from 'reducers/multifactorSlice'
import { getProfile } from 'reducers/profileSlice'
import { getCareplan } from 'reducers/recommendationsSlice'
import { clearRequestedRoute, setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { IntakeStatus } from 'types/Profile'
import { APPSTORE_URL, PLAYSTORE_URL } from '../../constants'
import useMessagingCenter from '../../hooks/useMessagingCenter'
import Button from '../Base/Button'
import Column from '../Base/Column'
import Icon from '../Base/Icon'
import Row from '../Base/Row'
import './Navbar.scss'

interface Props {
  medicationsLoaded: boolean
  profileLoaded: boolean
  isMobile: boolean
}
export default function Navbar({ profileLoaded, isMobile }: Props) {
  const theme = useTheme()

  const navigate = useNavigate()
  const location = useLocation()
  const profile = useAppSelector(getProfile)
  const errors = useAppSelector(getErrors)
  const careplan = useAppSelector(getCareplan)

  const accessToken = useAppSelector(getAccessToken)
  const dispatch = useAppDispatch()
  const [isHome, setIsHome] = useState<boolean>(false)
  const [isOnAppointment, setIsOnAppointment] = useState<boolean>(false)

  const [navbarExpanded, setNavbarExpanded] = useState<boolean>(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [unreadPatientMessagesCount, setUnreadPatientMessagesCount] = useState<number>(0)
  const [unreadTriaMessagesCount, setUnreadTriaMessagesCount] = useState<number>(0)
  const [careplanBadgeCount, setCareplanBadgeCount] = useState<number>(0)
  const [navigated, setNavigated] = useState<boolean>(false)

  const { getPatientMessages, getTriaMessages } = useMessagingCenter(accessToken!)
  const forceMultiFactor = useAppSelector(getMultifactor)
  const analytics = useAnalytics(accessToken!)

  const careplanBadgeOnLogin =
    (careplan?.pharmacistResources.filter((x) => x.viewed === false).length ?? 0) +
    (careplan?.allRecommendations.filter((x) => !x.viewed).length ?? 0)

  const auth = useAuth()
  const logout = () => {
    setNavigated(false)
    dispatch(setLoading(true))
    window.sessionStorage.setItem('visitedRecs', 'false')
    if (process.env.REACT_APP_ENV_NAME !== 'DEVELOPMENT') {
      auth.authService.logout(true).then(() => {
        dispatch(setLoginError(''))
        dispatch(clearRequestedRoute())
        dispatch(authLogout())
        window.scrollTo(0, 0)
        dispatch(setLoading(false))
      })
    } else {
      dispatch(authLogout())
      window.scrollTo(0, 0)
      dispatch(setLoading(false))
    }
    navigate('/')
    window.localStorage.removeItem('pkce')
    window.localStorage.removeItem('auth')
    window.localStorage.removeItem('tempUrl')
  }

  const login = () => {
    window.scrollTo(0, 0)
    navigate('/')
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENV_NAME !== 'DEVELOPMENT') {
      setAuthenticated(auth.authService.isAuthenticated())
    } else {
      setAuthenticated(accessToken === undefined || accessToken === '' ? false : true)
    }
  }, [])

  useEffect(() => {
    if (location.pathname === '/' || location.pathname.includes('/account/login')) {
      setIsHome(true)
    } else {
      setIsHome(false)
    }
    analytics.postAnalyticsPageView(window.location.pathname)

    if (location.pathname.includes('/appointment')) {
      setIsOnAppointment(true)
    } else {
      setIsOnAppointment(false)
    }

    profileLoaded &&
      setCareplanBadgeCount(
        (careplan?.pharmacistResources.filter((x) => x.viewed === false).length ?? 0) +
          (window.sessionStorage.getItem('visitedRecs') !== 'true' ? careplan?.allRecommendations.filter((x) => !x.viewed).length ?? 0 : 0),
      )
  }, [location])

  useEffect(() => {
    if (profileLoaded) {
      getPatientMessages().then((res) => {
        setUnreadPatientMessagesCount(res.data.patientInitiatedMessages?.filter((message) => message.hasNewMessage).length ?? 0)
      })

      getTriaMessages().then((res) => {
        setUnreadTriaMessagesCount(res.data.triaInitiatedMessages?.filter((message) => !message.viewedDate && !message.archivedDate).length ?? 0)
      })
      const comInterval = setInterval(() => {
        getPatientMessages().then((res) => {
          setUnreadPatientMessagesCount(res.data.patientInitiatedMessages?.filter((message) => message.hasNewMessage).length ?? 0)
        })

        getTriaMessages().then((res) => {
          setUnreadTriaMessagesCount(res.data.triaInitiatedMessages?.filter((message) => !message.viewedDate && !message.archivedDate).length ?? 0)
        })
      }, 300000)

      return () => {
        clearInterval(comInterval)
      }
    }
  }, [profileLoaded])

  const handleClick = (route: string) => {
    isMobile && setNavbarExpanded(false)
    navigate(route)
    window.scrollTo(0, 0)
    profileLoaded && setNavigated(true)
  }

  return (
    <BootstrapNav
      collapseOnSelect
      expand="lg"
      expanded={navbarExpanded}
      variant="dark"
      sticky={'top'}
      style={{
        backgroundColor: theme.colors.primary1,
      }}
    >
      <BootstrapNav.Brand
        onClick={() => {
          window.scrollTo(0, 0)
          handleClick('/')
        }}
        style={{ cursor: 'pointer' }}
      >
        <Image
          src={logo}
          height={45}
          onClick={() =>
            console.log(`Version ${process.env.REACT_APP_SITE_VERSION}
Release Date: ${process.env.REACT_APP_RELEASE_DATE}`)
          }
        />
      </BootstrapNav.Brand>
      <BootstrapNav.Toggle aria-controls="responsive-navbar-nav" onClick={() => setNavbarExpanded(!navbarExpanded)} />
      <BootstrapNav.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav
          style={{
            justifyItems: 'center',
            fontFamily: 'Raleway-Semi-Bold',
            fontWeight: 'bold',
          }}
        >
          {accessToken && errors && !errors.login && (
            <>
              {profileLoaded && profile?.intakeStatus === IntakeStatus.Complete && forceMultiFactor !== true && (
                <NavDropdown
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  title="My Tria Health"
                  className="link-text"
                  id="collapsible-nav-dropdown"
                >
                  <Column>
                    <NavDropdown.Item style={{ textAlign: 'left' }} onClick={() => handleClick('/profile')}>
                      Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item style={{ textAlign: 'left' }} onClick={() => handleClick('/careplan')}>
                      My Care Plan
                    </NavDropdown.Item>
                    <NavDropdown.Item style={{ textAlign: 'left' }} onClick={() => handleClick('/careplan/medications')}>
                      Medication List
                    </NavDropdown.Item>
                    <NavDropdown.Item style={{ textAlign: 'left' }} onClick={() => handleClick('/reimbursements')}>
                      Reimbursements
                    </NavDropdown.Item>
                  </Column>
                </NavDropdown>
              )}
              {(profile?.dashboards.includes('Blood Glucose') ||
                profile?.dashboards.includes('Blood Pressure') ||
                profile?.dashboards.includes('Digital Scale') ||
                profile?.hasDashboardOverridePermission) &&
                profile?.intakeStatus === IntakeStatus.Complete &&
                forceMultiFactor !== true && (
                  <NavDropdown
                    style={{
                      textAlign: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    title="My Readings"
                    className="link-text"
                    id="collapsible-nav-dropdown"
                  >
                    <Column>
                      {(profile?.dashboards.includes('Blood Glucose') || profile?.hasDashboardOverridePermission) && (
                        <NavDropdown.Item style={{ textAlign: 'left' }} onClick={() => handleClick('/dashboard/bloodglucose')}>
                          Blood Glucose Logs
                        </NavDropdown.Item>
                      )}
                      {(profile?.dashboards.includes('Blood Pressure') || profile?.hasDashboardOverridePermission) && (
                        <NavDropdown.Item style={{ textAlign: 'left' }} onClick={() => handleClick('/dashboard/bloodpressure')}>
                          Blood Pressure Logs
                        </NavDropdown.Item>
                      )}
                      {(profile?.dashboards.includes('Digital Scale') || profile?.hasDashboardOverridePermission) && (
                        <NavDropdown.Item style={{ textAlign: 'left' }} onClick={() => handleClick('/dashboard/digitalscale')}>
                          Digital Scale Logs
                        </NavDropdown.Item>
                      )}
                    </Column>
                  </NavDropdown>
                )}
              {profile?.hasSTOP && (
                <Nav.Link
                  className="link-text"
                  style={{ textAlign: 'center', justifyContent: 'center' }}
                  onClick={() => handleClick('/programs/stop')}
                >
                  S.T.O.P
                  <Icon name="ban-smoking" styles={{ marginLeft: 5 }} />
                </Nav.Link>
              )}
              {profile?.intakeStatus === IntakeStatus.Complete && forceMultiFactor !== true && (
                <Nav.Link className="link-text" style={{ textAlign: 'center', justifyContent: 'center' }} onClick={() => handleClick('/messaging')}>
                  Messages
                  {(unreadPatientMessagesCount > 0 || unreadTriaMessagesCount > 0) && (
                    <StyledBadge>
                      <Typography>{unreadPatientMessagesCount + unreadTriaMessagesCount}</Typography>
                    </StyledBadge>
                  )}
                </Nav.Link>
              )}
            </>
          )}
          <Nav.Link className="link-text" style={{ textAlign: 'center', justifyContent: 'center' }} onClick={() => handleClick('/resources')}>
            Resources
          </Nav.Link>
          {!isOnAppointment && (
            <StyledNavLink
              className="link-text"
              style={{
                backgroundColor: isMobile ? 'transparent' : theme.colors.primary1,
                border: isMobile ? 'none' : '1px solid white',
              }}
              onClick={() => handleClick('/appointment')}
            >
              Schedule
            </StyledNavLink>
          )}

          {accessToken && (
            <Row
              center
              className="d-flex"
              styles={{
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <StyledNavLink
                className="link-text"
                style={{
                  backgroundColor: isMobile ? 'transparent' : '#7fb3bf',
                }}
                onClick={logout}
              >
                Log Out
              </StyledNavLink>
            </Row>
          )}
          {isMobile && (
            <div className="link-text" style={{ textAlign: 'center', justifyContent: 'center' }}>
              <Row styles={{ marginTop: 10, justifyContent: 'center' }}>
                <Icon name={'play'} link={PLAYSTORE_URL} styles={{ marginRight: 10 }} />
                <Icon name={'ios'} link={APPSTORE_URL} />
              </Row>
            </div>
          )}
          {!isHome && accessToken === undefined && (
            <Row
              center
              className="d-flex"
              styles={{
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Button
                className={classNames('login-out-btn')}
                onClick={login}
                styles={{
                  borderRadius: 4,
                }}
                text="Login"
              />
            </Row>
          )}
        </Nav>
      </BootstrapNav.Collapse>
    </BootstrapNav>
  )
}

const StyledNavLink = styled(Nav.Link)`
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  justify-content: center;
`
const StyledBadge = styled.div`
  height: 28px;
  min-width: 28px;
  width: fit-content;
  margin-left: 10px;
  border-radius: 50%;
  padding-bottom: 2px;
  background-color: #e40046;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
`
